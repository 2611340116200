import {
  BrandTypemarkIcon,
  DiscordIcon,
  LogoIcon,
  TwitterIcon,
} from "../../assets/icons";
import { launchAppLink } from "../../constants/appLink";
import { DISCORD, TWITTER } from "../../constants/socialLinks";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <LogoIcon />
        <BrandTypemarkIcon />
      </div>
      <div className={styles.navigation_panel}>
        <a href="#vision-section">Vision</a>
        <span>|</span>
        <a href="#partners-section">Partners</a>
      </div>
      <div className={styles.launch_group}>
        <div className={styles.links_group}>
          <a href={DISCORD} aria-label="discord link" target="_blank">
            <DiscordIcon width="44px" height="44px" id="header_discord" />
          </a>
          <span>|</span>
          <a href={TWITTER} aria-label="twitter link" target="_blank">
            <TwitterIcon
              width="44px"
              height="44px"
              fill="#272727"
              id="header_twitter"
            />
          </a>
        </div>
        <a href={launchAppLink}>
          <button>Launch app</button>
        </a>
      </div>
      <div className={styles.burger_icon} aria-label="menu">
        <BurgerMenu />
      </div>
    </header>
  );
};

export default Header;
