import Feature, { FeatureItem } from "./Feature/Feature";
import { CodeIcon, HeadphonesIcon, ShieldIcon } from "../../assets/icons";
import styles from "./Features.module.scss";

const featuresList: FeatureItem[] = [
    {
        icon: <CodeIcon />,
        title: "Proprietary runes indexer",
        description: "We've developed our own indexer for tracking the history of newly etched and minted runes from block #840 000. When we say you can bridge ANY rune, we mean it."
    },
    {
        icon: <ShieldIcon />,
        title: "Top-tier security",
        description: "RunesKey has been audited by Hacken and Halborn, ensuring robust security. The distributed signature system further fortifies our platform. Your assets are safe with us."
    },
    {
        icon: <HeadphonesIcon />,
        title: "800+ supported tokens",
        description: "Our bridge supports Runes Protocol and over 5 other chains, with more integrations on the way. Cross-chain interoperability at its best."
    }
];

const Features = () => {
  return (
    <div className={styles.features}>
        {featuresList.map(feature => <Feature {...feature} key={feature.title}/>)}
    </div>
    )
}

export default Features