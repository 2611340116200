import { DiscordIcon, TwitterIcon } from "../../assets/icons";
import { DISCORD, TWITTER } from "../../constants/socialLinks";
import styles from "./StayConnected.module.scss";

const StayConnected = () => {
  return (
    <section className={styles.stayConnected}>
        <div className={styles.blurCard}>
            <h2>Stay Connected</h2>
            <p>Become a part of our community & join the conversation - follow us on X and Discord</p>
            <div className={styles.links_group}>
                <a href={TWITTER} aria-label="twitter link" target="_blank">
                    <TwitterIcon width="35px" height="35px" design="footer_card" color="white" fill="#d39661"/>
                </a>
                <span>|</span>
                <a href={DISCORD} aria-label="discord link" target="_blank">
                    <DiscordIcon width="40px" height="40px" color="white"/>
                </a>
            </div>
        </div>
    </section>
  )
}

export default StayConnected