import { useEffect, useState } from "react";
import { MenuIcon } from "../../assets/icons/MenuIcon";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { DiscordIcon, TwitterIcon } from "../../assets/icons";
import { DISCORD, TWITTER } from "../../constants/socialLinks";
import styles from "./BurgerMenu.module.scss";

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : document.body.style.removeProperty("overflow");

    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [isOpen]);

  const handleNavigationClick = () => setIsOpen(false);

  return (
    <div className={styles.container}>
      <button
        onClick={handleMenuClick}
        className={`${styles.icon} ${isOpen && styles.icon_open}`}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </button>
      {isOpen && (
        <div className={styles.menu}>
          <div className={styles.links}>
            <a href="#vision-section" onClick={handleNavigationClick}>
              Vision
            </a>
            <a href="#partners-section" onClick={handleNavigationClick}>
              Partners
            </a>
          </div>
          <div className={styles.socials}>
            <a href={DISCORD} aria-label="discord link" target="_blank">
              <DiscordIcon width="56px" height="56px" id="menu_discord" />
            </a>
            <span>|</span>
            <a href={TWITTER} aria-label="twitter link" target="_blank">
              <TwitterIcon
                width="52px"
                height="52px"
                fill="#2b2b2b"
                id="menu_twitter"
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;
