import guide_img from "../../assets/guide_img.png";
import { launchAppLink } from "../../constants/appLink";
import styles from "./Guide.module.scss";

const guideSteps: { id: number; step: string }[] = [
  {
    id: 1,
    step: "Connect your Xverse and/or MetaMask wallet.",
  },
  {
    id: 2,
    step: "Choose the assets and the chain you want to bridge to.",
  },
  {
    id: 3,
    step: "Review & confirm your transaction and enjoy quick and secure bridging.",
  },
];

const Guide = () => {
  return (
    <section className={styles.guide}>
      <img src={guide_img} alt="bridge guide image" />
      <div>
        <h2>How it Works</h2>
        <ul>
          {guideSteps.map(({ id, step }) => (
            <li key={id}>
              <span>{step}</span>
            </li>
          ))}
        </ul>
        <a href={launchAppLink}>
          <button>Bridge</button>
        </a>
      </div>
    </section>
  );
};

export default Guide;
