import React, { SVGProps } from "react";

export const LogoIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="27"
      height="36"
      viewBox="0 0 27 36"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9295 17.9568C19.6997 16.1866 22.5561 15.3721 26.641 15.3721V12.0561C22.5566 12.0561 19.6997 11.2422 17.9295 9.47145C16.1543 7.69676 15.3381 4.83982 15.3381 0.76001H12.0362C12.0362 4.83982 11.2189 7.69732 9.44476 9.47145C7.67514 11.2422 4.8182 12.0561 0.733887 12.0561V15.3721C4.81764 15.3721 7.67514 16.1866 9.44476 17.9568C11.0108 19.5222 11.8303 21.9336 12.0013 25.2828V35.239H19.4567V31.8781H15.3623V29.8559H17.5813V26.4944H15.3623V25.5274C15.5051 22.0512 16.3253 19.561 17.9295 17.9568ZM9.16464 13.7149C10.1586 13.2059 11.0316 12.5748 11.7893 11.8171C12.547 11.0588 13.1781 10.1853 13.6866 9.18851C14.1956 10.1853 14.8262 11.0583 15.5845 11.8171C16.3433 12.5753 17.2151 13.2064 18.2096 13.7149C17.2157 14.2234 16.3433 14.8546 15.5845 15.6128C14.8262 16.3711 14.1956 17.2458 13.6866 18.2414C13.1781 17.2458 12.547 16.3711 11.7893 15.6128C11.0316 14.8551 10.1586 14.224 9.16464 13.7149Z"
        fill="white"
      />
    </svg>
  );
};
