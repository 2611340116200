import { launchAppLink } from "../../constants/appLink";
import styles from "./Poster.module.scss";

const Poster = () => {
  return (
    <section className={styles.poster}>
      <div className={styles.text_wrapper}>
        <div>
          <h1>BRIDGE ANY RUNE TO & FROM ANY EVM AND NON-EVM CHAIN</h1>
          <p>
            High-speed bridging. Native to Bitcoin, empowering for the
            ecosystem.
          </p>
          <a href={launchAppLink}>
            <button>Launch app</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Poster;
