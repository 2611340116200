import React, { SVGProps } from "react";

export const CodeIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="69"
        height="69"
        rx="6"
        fill="url(#paint0_linear_592_2)"
      />
      <rect
        x="0.5"
        y="9.5"
        width="69"
        height="1.5"
        fill="url(#paint1_linear_592_2)"
      />
      <path
        d="M10.9707 36.5L25.9531 46.1945C26.368 46.4629 26.4107 47.0537 26.0389 47.3791L11.9007 59.75"
        stroke="url(#paint2_linear_592_2)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="29.375"
        y="59.375"
        width="30.75"
        height="0.75"
        rx="0.375"
        fill="url(#paint3_linear_592_2)"
        stroke="url(#paint4_linear_592_2)"
        strokeWidth="0.75"
      />
      <defs>
        <linearGradient
          id="paint0_linear_592_2"
          x1="-6.9252"
          y1="35"
          x2="75.748"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_592_2"
          x1="-6.9252"
          y1="10.25"
          x2="75.748"
          y2="10.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#272522" />
          <stop offset="1" stopColor="#3D4F56" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_592_2"
          x1="9.26936"
          y1="48.125"
          x2="28.2123"
          y2="48.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#272522" />
          <stop offset="1" stopColor="#3D4F56" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_592_2"
          x1="25.6102"
          y1="59.75"
          x2="63.3524"
          y2="59.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#272522" />
          <stop offset="1" stopColor="#3D4F56" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_592_2"
          x1="25.6102"
          y1="59.75"
          x2="63.3524"
          y2="59.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#272522" />
          <stop offset="1" stopColor="#3D4F56" />
        </linearGradient>
      </defs>
    </svg>
  );
};
