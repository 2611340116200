import { VisionIcon } from "../../assets/icons";
import styles from "./Vision.module.scss";

const Vision = () => {
  return (
    <section className={styles.vision}>
        <div>
          <h2 id="vision-section">Our vision</h2>
          <VisionIcon />
        </div>
        <p>
          The launch of Runes Protocol marked a significant milestone in the
          development of the Bitcoin ecosystem. But before it comes into full
          power, we need approachable solutions for jumpstarting mass adoption
          and onboarding the new wave of users.
          <br/><br/>
          At RunesKey, that's where our focus is — we help the community move
          assets across different blockchain networks effortlessly.
        </p>
    </section>
  );
}

export default Vision;