import React, { SVGProps } from "react";

export const BrandTypemarkIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="241"
      height="40"
      viewBox="0 0 241 40"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.8529 17.8321H98.9529V22.1661H90.8529V23.4461H104.567V27.8161H86.4829V16.2341L90.5689 12.1841H104.567V16.5541H91.9549L90.8529 17.8341V17.8321Z"
        fill="white"
      />
      <path
        d="M83.3693 18.2581V27.8161H78.9993V20.0701L75.4813 16.5521H68.2333V27.8141H63.8633V12.1841H77.2933L83.3693 18.2601V18.2581Z"
        fill="white"
      />
      <path
        d="M36.683 22.0961L38.459 23.8721V27.8161H34.089V25.6841L32.775 24.3701H24.745V27.8161H20.375V12.1841H34.373L38.459 16.2341V20.3201L36.683 22.0961ZM24.745 20.0361H32.597L34.089 18.5081V18.0461L32.597 16.5541H24.745V20.0361Z"
        fill="white"
      />
      <path
        d="M111.519 16.5541L112.833 17.8681H122.675L125.695 20.8881V24.8321L122.675 27.8161H107.575V23.5181H121.751L120.437 22.2041H110.595L107.575 19.1841V15.2041L110.595 12.1841H125.695V16.5541H111.519Z"
        fill="white"
      />
      <path
        d="M60.845 12.1841V23.7301L56.795 27.8161H45.427L41.377 23.7301V12.1841H45.747V21.9541L47.239 23.4461H54.985L56.477 21.9541V12.1841H60.847H60.845Z"
        fill="white"
      />
      <path
        d="M163.685 17.8321H171.903V22.1661H163.685V23.4461H177.599V27.8161H159.253V16.2341L163.399 12.1841H177.601V16.5541H164.805L163.687 17.8341L163.685 17.8321Z"
        fill="white"
      />
      <path
        d="M156.247 22.5221V27.8161H151.849V24.2981L148.497 20.7801H142.333V27.8141H137.935V12.1841H142.333V16.4121H145.649L149.939 12.1841H156.175L150.985 17.3001L156.247 22.5221Z"
        fill="white"
      />
      <path
        d="M199.733 12.1841V17.4781L194.939 22.2041H192.055V27.8181H187.621V22.2041H184.737L179.943 17.4781V12.1841H184.377V15.6661L186.611 17.8341H193.063L195.297 15.6661V12.1841H199.731H199.733Z"
        fill="white"
      />
    </svg>
  );
};
