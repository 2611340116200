import React, { SVGProps } from "react";

export const HeadphonesIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48 13.5C28.9767 13.5 13.5 28.9767 13.5 48V62.2934C13.5 65.8262 16.5946 68.7 20.4 68.7H23.85C24.765 68.7 25.6425 68.3365 26.2895 67.6895C26.9365 67.0425 27.3 66.165 27.3 65.25V47.5067C27.3 46.5917 26.9365 45.7141 26.2895 45.0671C25.6425 44.4201 24.765 44.0566 23.85 44.0566H20.7174C22.6356 30.7051 34.1241 20.4 48 20.4C61.8759 20.4 73.3644 30.7051 75.2826 44.0566H72.15C71.235 44.0566 70.3575 44.4201 69.7105 45.0671C69.0635 45.7141 68.7 46.5917 68.7 47.5067V68.7C68.7 72.5053 65.6054 75.6 61.8 75.6H54.9V75.6C54.9 73.6946 53.3554 72.15 51.45 72.15H46.275C43.4169 72.15 41.1 74.4669 41.1 77.325V77.325C41.1 80.1831 43.4169 82.5 46.275 82.5H61.8C69.4107 82.5 75.6 76.3107 75.6 68.7C79.4053 68.7 82.5 65.8262 82.5 62.2934V48C82.5 28.9767 67.0233 13.5 48 13.5Z"
        fill="url(#paint0_linear_511_19115)"
      />
      <path
        d="M72.841 43.5H75C79.1421 43.5 82.5 46.8579 82.5 51V62.781C82.5 63.4128 82.313 64.0305 81.9625 64.5563L81.6982 64.9527C81.2373 65.644 80.644 66.2373 79.9527 66.6982C79.1586 67.2276 78.2685 67.5963 77.3326 67.7835L77.2533 67.7993C75.7658 68.0968 74.2342 68.0968 72.7467 67.7993L72.599 67.7698C71.7194 67.5939 70.9115 67.1615 70.2773 66.5273C69.4594 65.7094 69 64.6003 69 63.4437V47.341C69 46.3223 69.4047 45.3453 70.125 44.625C70.8453 43.9047 71.8223 43.5 72.841 43.5Z"
        stroke="url(#paint1_linear_511_19115)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M23.159 44.25H21C16.8579 44.25 13.5 47.6079 13.5 51.75V61.3117C13.5 63.3269 14.416 65.2328 15.9896 66.4917C16.8178 67.1542 17.7927 67.6085 18.8327 67.8165L20.5748 68.165C20.8576 68.2215 21.1452 68.25 21.4336 68.25H23.1264C24.166 68.25 25.163 67.837 25.8981 67.1019C26.6036 66.3964 27 65.4394 27 64.4417V48.091C27 47.0723 26.5953 46.0953 25.875 45.375C25.1547 44.6547 24.1777 44.25 23.159 44.25Z"
        stroke="url(#paint2_linear_511_19115)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_511_19115"
          x1="6.0748"
          y1="48"
          x2="88.748"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_511_19115"
          x1="83.9528"
          y1="55.875"
          x2="67.7776"
          y2="55.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#272522" />
          <stop offset="1" stopColor="#3D4F56" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_511_19115"
          x1="12.0472"
          y1="56.25"
          x2="28.2224"
          y2="56.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#272522" />
          <stop offset="1" stopColor="#3D4F56" />
        </linearGradient>
      </defs>
    </svg>
  );
};
