import { AuditorsIcons } from '../../assets/icons'
import styles from "./Auditors.module.scss";

const Auditors = () => {
  return (
    <section className={styles.auditors}>
        <h2>Audited By</h2>
        <div>
            <AuditorsIcons />
        </div>
    </section>
  )
}

export default Auditors