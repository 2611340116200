import React, { SVGProps } from "react";

export const MenuIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path
        d="M5.5 22H38.5"
        stroke="url(#paint0_linear_701_15786)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 11H38.5"
        stroke="url(#paint1_linear_701_15786)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 33H38.5"
        stroke="url(#paint2_linear_701_15786)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_701_15786"
          x1="1.94882"
          y1="22.5"
          x2="41.4882"
          y2="22.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_701_15786"
          x1="1.94882"
          y1="11.5"
          x2="41.4882"
          y2="11.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_701_15786"
          x1="1.94882"
          y1="33.5"
          x2="41.4882"
          y2="33.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
