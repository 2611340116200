import React, { SVGProps } from "react";

export const ShieldIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.3999 9.72L13.3799 22.5V54C13.3799 70.88 47.9999 87 47.9999 87C47.9999 87 82.6199 70.88 82.6199 54V22.5L51.5999 9.72C50.4592 9.24471 49.2356 9 47.9999 9C46.7641 9 45.5406 9.24471 44.3999 9.72Z"
        fill="url(#paint0_linear_511_19101)"
        stroke="url(#paint1_linear_511_19101)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.8999 41.4H47.9999V23.56L35.3799 50H48.2799V67.84L60.8999 41.4Z"
        stroke="url(#paint2_linear_511_19101)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_511_19101"
          x1="5.92886"
          y1="48"
          x2="88.8896"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_511_19101"
          x1="5.92886"
          y1="48"
          x2="88.8896"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_511_19101"
          x1="32.6336"
          y1="45.7"
          x2="63.2107"
          y2="45.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#272522" />
          <stop offset="1" stopColor="#3D4F56" />
        </linearGradient>
      </defs>
    </svg>
  );
};
