import React from "react";
import styles from "./Feature.module.scss";

export type FeatureItem = {
    icon: React.ReactElement;
    title: string;
    description: string;
}
const Feature: React.FC<FeatureItem> = ({icon, title, description}) => {
  return (
    <>
      <div className={styles.icon}>{icon}</div>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
    </>
  );
}

export default Feature;