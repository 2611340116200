import React, { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  design?: "footer_card";
};

export const TwitterIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip_${props.id})`}>
        <path
          d="M50.1767 9.03076H47.1201L8.9126 53.3515H12.9244L50.1767 9.03076Z"
          stroke={props.color || `url(#0_${props.id})`}
          strokeWidth="1.79489"
        />
        <path
          d="M21.7125 9.22168H9.104L39.0969 53.3514H52.2785L21.7125 9.22168Z"
          stroke={props.color || `url(#1_${props.id})`}
          strokeWidth="1.79489"
        />
      </g>
      <defs>
        <linearGradient
          id={`0_${props.id}`}
          x1="4.4721"
          y1="31.1911"
          x2="53.9133"
          y2="31.1911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0D5BD" offset="0" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id={`1_${props.id}`}
          x1="4.45793"
          y1="31.2865"
          x2="56.188"
          y2="31.2865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0D5BD" offset="0" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <clipPath id={`clip_${props.id}`}>
          <rect
            width="60.75"
            height="60.75"
            fill="white"
            transform="translate(0.125 0.625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
