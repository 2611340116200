import Poster from "../Poster/Poster";
import Header from "../Header/Header";
import Vision from "../Vision/Vision";
import Features from "../Features/Features";
import Partners from "../Partners/Partners";
import Guide from "../Guide/Guide";
import Auditors from "../Auditors/Auditors";
import StayConnected from "../StayConnected/StayConnected";
import styles from "./Layout.module.scss";

const Layout = () => {
  return (
    <div className={styles.layout}>
      <Header />
      <main>
        <Poster />
        <Vision />
        <Features />
        <Partners />
        <Guide />
        <Auditors />
        <StayConnected />
      </main>
      <footer>Copyright © 2024 runeskey.com - All Rights Reserved.</footer>
    </div>
  );
};

export default Layout;
