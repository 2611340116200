import Layout from "./components/Layout/Layout";
import styles from "./App.module.scss";

function App() {
  return (
    <div className={styles.container}>
      <Layout />
    </div>
  );
}

export default App;
