import hypra from "../../assets/Hypra.svg";
import boosty from "../../assets/Boosty.svg";
import runesDex from "../../assets/RunesDex.svg";
import stellar from "../../assets/Stellar.svg";
import bitlayer from "../../assets/bitlayer_logo.png";

import "./index.scss";

const Partners = () => {
  return (
    <section className="backed-by">
      <div className="backed-by__wrapper">
        <span id="partners-section" className="backed-by__title">
          Partners
        </span>
        <div className="backed-by__partners">
          <a
            className="backed-by__partners__link"
            href="https://hypra.fund/"
            target="_blank"
            aria-label="hypra"
          >
            <img loading="lazy" src={hypra} alt="hypra" />
          </a>
          <a
            className="backed-by__partners__link"
            href="https://boostylabs.com/"
            target="_blank"
            aria-label="boosty"
          >
            <img loading="lazy" src={boosty} alt="boosty" />
          </a>
          <a
            className="backed-by__partners__link"
            href="https://stellar.org/"
            target="_blank"
            aria-label="stellar"
          >
            <img loading="lazy" src={stellar} alt="stellar" />
          </a>
        </div>
        <div className="backed-by__partners backed-by__partners-second">
          <a
            className="backed-by__partners__link"
            href="https://www.runesdex.com/"
            target="_blank"
            aria-label="runes dex"
          >
            <img loading="lazy" src={runesDex} alt="runes dex" />
          </a>
          <a
            className="backed-by__partners__link"
            href="https://www.bitlayer.org/"
            target="_blank"
            aria-label="bitlayer"
          >
            <img loading="lazy" src={bitlayer} alt="bitlayer" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Partners;
