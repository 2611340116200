import React, { SVGProps } from "react";

export const VisionIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="233"
      height="48"
      viewBox="0 0 233 48"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48 24L29.987 25.3735L45.6238 34.4638L28.8046 27.8294L38.9703 42.8326L26.6602 29.552L29.3262 47.5L23.9942 30.1573L18.6622 47.5L21.3398 29.552L9.02971 42.8326L19.1954 27.8294L2.37622 34.4638L18.013 25.3735L0 24L18.013 22.6149L2.37622 13.5362L19.1954 20.159L9.02971 5.15577L21.3398 18.4364L18.6622 0.5L23.9942 17.8311L29.3262 0.5L26.6602 18.4364L38.9703 5.15577L28.8046 20.159L45.6238 13.5362L29.987 22.6149L48 24Z"
        fill="url(#paint0_linear_511_19002)"
      />
      <circle
        cx="86.5487"
        cy="25.3819"
        r="22.1181"
        fill="url(#paint1_linear_511_19002)"
      />
      <path
        d="M137.596 25.1179L125.102 12.6236L135.726 2L148.22 14.4944L137.596 25.1179ZM125.833 12.6236L137.596 24.3872L147.484 14.4995L135.72 2.73588L125.833 12.6236Z"
        fill="url(#paint2_linear_511_19002)"
      />
      <path
        d="M159.984 47.5L147.49 35.0056L158.113 24.3821L170.608 36.8764L159.984 47.5ZM148.22 35.0056L159.984 46.7693L169.872 36.8816L158.108 25.1179L148.22 35.0056Z"
        fill="url(#paint3_linear_511_19002)"
      />
      <path
        d="M135.721 47.5L125.097 36.8764L137.592 24.3821L148.215 35.0056L135.721 47.5ZM125.833 36.8764L135.721 46.7641L147.484 35.0005L137.597 25.1128L125.833 36.8764Z"
        fill="url(#paint4_linear_511_19002)"
      />
      <path
        d="M158.108 25.1179L147.485 14.4944L159.979 2L170.603 12.6236L158.108 25.1179ZM148.221 14.4944L158.108 24.3821L169.872 12.6184L159.984 2.73069L148.221 14.4944Z"
        fill="url(#paint5_linear_511_19002)"
      />
      <path
        d="M147.855 14.4926L137.599 24.7492L147.855 35.0059L158.112 24.7492L147.855 14.4926Z"
        fill="url(#paint6_linear_511_19002)"
      />
      <path
        d="M232.511 22.6894L231.846 18.9138L220.148 20.9744L230.433 15.0331L228.517 11.7169L218.231 17.6538L225.866 8.55375L222.93 6.09063L215.296 15.1906L219.356 4.02563L215.755 2.7175L211.691 13.8781V2H207.858V13.8781L203.794 2.7175L200.193 4.02563L204.253 15.1906L196.619 6.09063L193.683 8.55375L201.318 17.6538L191.032 11.7169L189.116 15.0331L199.401 20.9744L187.703 18.9138L187.038 22.6894L198.736 24.75L187.038 26.815L187.703 30.5863L199.401 28.5256L189.116 34.4669L191.032 37.7831L201.318 31.8463L193.683 40.9463L196.619 43.4094L204.253 34.3094L200.193 45.4744L203.794 46.7825L207.858 35.6219V47.5H211.691V35.6219L215.755 46.7825L219.356 45.4744L215.296 34.3094L222.93 43.4094L225.866 40.9463L218.231 31.8463L228.517 37.7831L230.433 34.4669L220.148 28.5256L231.846 30.5863L232.511 26.815L220.813 24.75L232.511 22.6894Z"
        fill="url(#paint7_linear_511_19002)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_511_19002"
          x1="-5.16535"
          y1="24"
          x2="52.3465"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_511_19002"
          x1="59.6703"
          y1="25.3819"
          x2="112.672"
          y2="25.3819"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_511_19002"
          x1="122.614"
          y1="13.559"
          x2="150.313"
          y2="13.559"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_511_19002"
          x1="145.002"
          y1="35.941"
          x2="172.701"
          y2="35.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_511_19002"
          x1="122.609"
          y1="35.941"
          x2="150.308"
          y2="35.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_511_19002"
          x1="144.997"
          y1="13.559"
          x2="172.696"
          y2="13.559"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_511_19002"
          x1="141.623"
          y1="30.9813"
          x2="153.912"
          y2="18.6922"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_511_19002"
          x1="182.144"
          y1="24.75"
          x2="236.629"
          y2="24.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#E0D5BD" />
          <stop offset="1" stopColor="#70909D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
